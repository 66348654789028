@import '../../../../scss/theme-bootstrap';

.header-offers-banner-item {
  color: $color-white;
  overflow: hidden;
  text-align: center;
  width: auto;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  //IE 11 fix for ellipsis since there is no line-clamp support
  @include ie11-only {
    display: inline-block;
    height: 100%;
    max-height: 40px;
  }
  &__offer-text {
    display: inline;
    p {
      display: inline;
      margin: 0;
      font-size: 15px;
      font-weight: 500;
      line-height: 1.3;
    }
    a {
      text-decoration: none;
    }
  }
  a {
    font-size: 15px;
  }
}

